import dayjs from 'dayjs';
import {DecimalPipe} from '@angular/common';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {SessionStorageService} from './session-storage.service';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  constructor(private router: Router,
              private sessionStorageService: SessionStorageService,
              private translateService: TranslateService) {
  }

  public formatBoolean(value: any): string {
    let result = '-';
    if (value !== null && value !== undefined) {
      result = value ? this.translateService.instant('sharedService.yes') :
        this.translateService.instant('sharedService.no');
    }
    return result;
  }

  public formatDate(value: any): string {
    return dayjs(value).format('YYYY-MM-DD');
  }

  public formatDateAsText(value: any): string {
    return value !== null && value !== undefined ? this.formatDate(value) : '-';
  }

  public formatDateTime(value: any): string {
    return dayjs(value).format(`YYYY-MM-DD HH:mm:ss`);
  }

  public formatDateTimeAsText(value: any): string {
    return value !== null && value !== undefined ? this.formatDateTime(value) : '-';
  }

  public formatDecimalNumber(value: number): string {
    return value !== null && value !== undefined ? new DecimalPipe('en').transform(value, '.2-2') : '-';
  }

  public formatInteger(value: number): string {
    return value !== null && value !== undefined ? new DecimalPipe('en').transform(value, '.0-0') : '-';
  }

  public formatText(value: number | string): any {
    return value !== null && value !== undefined && value !== '' ? value : '-';
  }

  public async logout(): Promise<any> {
    this.sessionStorageService.clear();
    await this.router.navigate(['login']);
  }
}
