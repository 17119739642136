import {Pipe, PipeTransform} from '@angular/core';
import {SharedService} from '../services/shared.service';

@Pipe({
  name: 'decimal'
})
export class DecimalPipe implements PipeTransform {
  constructor(private sharedService: SharedService) {
  }

  transform(value: number, ...args: unknown[]): unknown {
    return this.sharedService.formatDecimalNumber(value);
  }
}
