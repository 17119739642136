import {Directive, ElementRef, AfterViewInit} from '@angular/core';

@Directive({
  selector: '[appAutofocus]'
})
export class AutofocusDirective implements AfterViewInit {
  constructor(private elementRef: ElementRef) {
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.elementRef.nativeElement.focus();
    });
  }
}
