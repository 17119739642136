import {Pipe, PipeTransform} from '@angular/core';
import {SharedService} from '../services/shared.service';

@Pipe({
  name: 'date'
})
export class DatePipe implements PipeTransform {
  constructor(private sharedService: SharedService) {
  }

  transform(value: Date, ...args: unknown[]): unknown {
    return this.sharedService.formatDate(value);
  }
}
