import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {
  private sessionStorage: Storage;
  private readonly twoFactorAuthenticationToken: string;
  private readonly userToken: string;

  constructor() {
    this.sessionStorage = window.sessionStorage;
    this.twoFactorAuthenticationToken = 'twoFactorAuthenticationToken';
    this.userToken = 'userToken';
  }

  public clear(): any {
    this.sessionStorage.clear();
  }

  private getObject(key: string): any {
    const value = this.sessionStorage.getItem(key);
    return JSON.parse(value);
  }

  public getTwoFactorAuthenticationToken(): any {
    return this.getObject(this.twoFactorAuthenticationToken);
  }

  public getUserToken(): any {
    return this.getObject(this.userToken);
  }

  private setObject(key: string, value: any): void {
    this.sessionStorage.setItem(key, JSON.stringify(value));
  }

  public setTwoFactorAuthenticationToken(twoFactorAuthenticationToken: string): any {
    return this.setObject(this.twoFactorAuthenticationToken, twoFactorAuthenticationToken);
  }

  public setUserToken(userToken: string): any {
    return this.setObject(this.userToken, userToken);
  }
}
